<template>
   <div class="delivery-date">
      <ul class="delivery-date__list" data-date="day">
         <li v-for="date in getDeliveryDates()" :key="date.day" class="delivery-date__item">
            <button
               :class="{ 'delivery-date__btn--selected': date.day === presentDay }"
               @click="setDay(date.day)"
               class="delivery-date__btn btn"
            >
               <span class="delivery-date__day">{{ date.dayOfWeek }}</span>
               <span class="delivery-date__date">{{ date.day }} {{ date.month }}</span>
            </button>
         </li>
      </ul>

      <ul class="delivery-date__list" data-date="time">
         <li v-for="(time, idx) in timeOFDelivery" :key="idx" class="delivery-date__item">
            <label class="delivery-date__label">
               <input
                  :checked="!idx"
                  class="delivery-date__input"
                  type="radio"
                  name="time"
                  data-custom-radio="true"
               />

               <span class="delivery-date__text">
                  {{ time.start }}&nbsp;&mdash;&nbsp;{{ time.end }}
               </span>
            </label>
         </li>
      </ul>
   </div>
</template>

<script>
   export default {
      name: 'DeliveryDate',

      data() {
         return {
            presentDay: new Date().getDate(),
            timeOFDelivery: [
               { start: '10:00', end: '15:00' },
               { start: '12:00', end: '20:00' }
            ]
         };
      },

      methods: {
         setDay(value) {
            this.presentDay = value;
         },

         getDeliveryDates() {
            const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
            const months = [
               'Янв.',
               'Фев.',
               'Мар.',
               'Апр.',
               'Май',
               'Июн.',
               'Июл.',
               'Авг.',
               'Сен.',
               'Окт.',
               'Ноя.',
               'Дек.'
            ];

            const date = new Date(),
               DAY_MS = 86400000;

            return [...new Array(5)].map((item, idx) => ({
               day: new Date(date.getTime() + DAY_MS * idx).getDate(),
               dayOfWeek: days[new Date(date.getTime() + DAY_MS * idx).getDay()],
               month: months[new Date(date.getTime() + DAY_MS * idx).getMonth()]
            }));
         }
      }
   };
</script>
