<template>
   <div class="params">
      <div class="params__wrap" data-param="color">
         <h4 class="params__title">Цвета:</h4>

         <ul class="params__list">
            <li
               v-for="color in product.colors"
               :key="color.name"
               :title="color.name"
               :class="{ 'params__item--selected': color.name === selectedColor }"
               class="params__item"
            >
               <button
                  :style="{ '--color': color.value }"
                  @click="setParam('colors', color)"
                  class="params__btn btn"
               ></button>
            </li>
         </ul>
      </div>

      <div class="params__wrap" data-param="size">
         <h4 class="params__title">Размеры:</h4>

         <ul class="params__list">
            <li v-for="size in product.sizes" :key="size.name" class="params__item">
               <button
                  :class="{ 'params__btn--selected': size.name === selectedSize }"
                  @click="setParam('sizes', size)"
                  class="params__btn btn"
               >
                  {{ size.name }}
               </button>
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'ProductParams',

      props: {
         product: { type: Object, required: true }
      },

      data() {
         return {
            item: {},
            selectedParams: { colors: '', sizes: '' }
         };
      },

      methods: {
         setParam(type, value) {
            this.selectedParams[type] = value;
            this.item.selectedParams = this.selectedParams;

            this.$store.commit('fastBuy/ADD_PRODUCT', this.item);
         }
      },

      computed: {
         selectedColor() {
            return this.selectedParams.colors.name;
         },

         selectedSize() {
            return this.selectedParams.sizes.name;
         }
      },

      watch: {
         product: {
            handler(value) {
               this.item = value;
               this.selectedParams = value.selectedParams;
            },
            deep: true,
            immediate: true
         }
      }
   };
</script>
