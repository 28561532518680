<template>
   <div class="modal-fast-buy">
      <div class="modal-fast-buy__inner">
         <product-card class="modal-fast-buy__selected-product" />

         <fast-buy-form class="modal-fast-buy__fast-buy-form" />

         <delivery-date class="modal-fast-buy__delivery-date" />

         <button class="modal-fast-buy__btn btn" form="fast-buy-form">Оформить заказ</button>
      </div>
   </div>
</template>

<script>
   import ProductCard from './modules/ProductCard';
   import FastBuyForm from './modules/FastBuyForm';
   import DeliveryDate from './modules/DeliveryDate';

   export default {
      name: 'ModalFastBuy',

      components: {
         ProductCard,
         FastBuyForm,
         DeliveryDate
      },

      emits: {
         'update:title': (value) => typeof value === 'string'
      },

      beforeMount() {
         this.$emit('update:title', 'Купить в 1 клик');
      }
   };
</script>
