<template>
   <article class="selected-product">
      <div class="selected-product__inner">
         <img :src="product.image" :alt="product.imageDescription" class="selected-product__img" />
      </div>

      <div class="selected-product__wrap">
         <h3 class="selected-product__title">
            <router-link
               :to="{ name: 'Product', params: { id: product.id } }"
               @click="closeModal"
               class="selected-product__link"
            >
               {{ product.name }}
            </router-link>
         </h3>

         <product-params :product="product" class="selected-product__params" />

         <div class="product-price">
            <div class="product-price__count count">
               <button @click="decreaseProductsCount()" class="count__decrease btn"></button>

               <input
                  v-model.trim="productsCount"
                  @input="setProductsCount()"
                  class="count__input input"
                  type="text"
               />

               <button @click="increaseProductsCount()" class="count__increase btn"></button>
            </div>

            <p class="product-price__total-amount">
               Итого, к оплате: <span>{{ totalAmount }}&nbsp;{{ currency }}</span>
            </p>
         </div>
      </div>
   </article>
</template>

<script>
   import ProductParams from './ProductParams';
   import { mapState } from 'vuex';

   export default {
      name: 'ProductCard',

      components: {
         ProductParams
      },

      inject: ['closeModal'],

      data() {
         return {
            productsCount: 0
         };
      },

      methods: {
         decreaseProductsCount() {
            if (this.productsCount > 1) {
               this.productsCount--;
            }
         },

         increaseProductsCount() {
            this.productsCount++;
         },

         setProductsCount() {
            const value = +this.productsCount.replace(/\D/g, (match, offset, string) =>
               string.length > 1 ? '' : 1
            );

            this.productsCount = value >= 1 ? value : 1;
         }
      },

      computed: {
         ...mapState('fastBuy', ['product']),

         totalAmount() {
            return this.product.price * this.productsCount;
         }
      },

      watch: {
         'product.count': {
            handler(value) {
               this.productsCount = value;
            },
            immediate: true
         }
      }
   };
</script>
